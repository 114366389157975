@use 'libs/styles/responsive';
@import 'config';

body.home {
    background-color: $color-black;
}

body {
    padding-top: 115px;

    @include responsive.target(md, xxl) {
        padding-top: 85px;
    }
}