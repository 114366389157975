@import 'libs/styles/responsive';

.intro {
    width: 1024px;
    max-width: calc(100% - 60px);
    margin: 30px auto 0;

    p {
        margin-bottom: 1em;
        font-size: 15px;
    }

    @include target(lg, xxl) {
        margin-top: 60px;

        h1 {
            margin-bottom: 50px;
        }

        p {
            font-size: 18px;
            line-height: 1.375;
        }
    }
}