@import 'app/styles/config';
@import 'libs/styles/responsive';

.services {
    display: grid;
    grid-gap: 25px;
    width: 1024px;
    max-width: calc(100% - 60px);
    margin: 30px auto 0;

    @include target(md, xxl) {
        grid-template-columns: 1fr 1fr;
    }

    @include target(lg, xxl) {
        grid-gap: 40px;
    }
}

.service {
    padding: 20px 25px;
    border-radius: 5px;
    border: 1px solid #F4F4F4;
    background: linear-gradient(166deg, #F9F9F9 0%, #FBFBFB 100%);
    box-shadow: 0px 30px 30px -20px rgba(194, 194, 194, 0.29);

    h1 {
        font-size: 18px;
        padding-bottom: 5px;
    }

    p {
        color: #686868;
        font-size: 14px;
    }

    a {
        display: inline-block;
        margin-top: 1em;
        text-decoration: underline;
        text-underline-offset: 3px;
    }

    @include target(lg, xxl) {
        padding: 30px 35px;

        h1 {
            font-size: 24px;
            margin-bottom: 15px;
            padding-bottom: 10px;
        }

        p,
        a {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}

.service--highlight {
    background: linear-gradient(114deg, #866800 0%, #FFE600 130%);
    box-shadow: 0px 30px 30px -20px rgba(0, 0, 0, 0.29);
    border: none;

    h1,
    p,
    a {
        color: $color-white;
    }
}